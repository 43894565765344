<script>
import ApiService from "@services/api.service";

export default {
  name: "Flyer",
  data() {
    return {
      flyerList: null,
      newFile: null,
      showModal: false
    };
  },
  computed: {
    rowCount: function () {
      return this.learningGroupList.length;
    }
  },
  created() {
    this.loadFlyer();
  },
  methods: {
    loadFlyer() {
      this.changeLoader(true);
      ApiService.get("flyer")
        .then(resp => {
          this.flyerList = resp;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    async deleteFlyer(item) {
      if (await this.showConfirm(`Delete File ?`)) {
        this.changeLoader(true);
        let qs = {
          code: item.code
        };
        ApiService.delete("flyer", qs)
          .then(() => {
            this.loadFlyer();
          })
          .finally(() => {
            this.changeLoader(false);
          });
      }
    },
    fileChanged(event) {
      var reader = new FileReader();
      var x = event.target.files[0];
      this.learningGroup.filePayload.filename = event.target.files[0].name;
      reader.onloadend = () => {
        this.learningGroup.filePayload.base64File = reader.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
};
</script>

<template>
  <div>
    <router-link :to="{ name: 'cms-flyer-detail' }">
      <b-btn class="mb-2" variant="outline-primary" :to="{ name: `cms-flyer-detail` }">Create</b-btn>
    </router-link>
    <b-row>
      <b-col v-for="item in flyerList" :key="item.code" md="4">
        <b-card no-body>
          <b-card-body>
            <b-card-title>{{ item.description }}</b-card-title>
          </b-card-body>
          <b-img fluid :src="item.coverImageUrl" />
          <b-card-body class="text-right">
            <router-link :to="{ name: 'cms-flyer-detail', params: { flyerCode: item.code } }">
              <b-button class="mr-2" variant="primary"> View </b-button>
            </router-link>
            <b-button variant="danger" @click="deleteFlyer(item)"> <FeatherIcon icon="TrashIcon" /> </b-button>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
